import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
  name: 'organisation-header-component',
  props: {
    title: {
      type:String,
      required:true
    },
    icon: {
      type:String,
      required: true,
    }
  }
})

export default class OrganisationHeaderComponent extends Vue {

}
