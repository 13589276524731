import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'organisation-card-component',
    props: {
        organisation: {
            type: Object,
            required: true,
        }, // title, webSite, tel, address, description, map
    },
})

export default class OrganisationCardComponent extends Vue {
    locale$: any = locale;
    locale: any = null;
    created() {
        this.locale$.subscribe((res: any) => {
            if (res && res.organisations) {
                this.locale = res.organisations.organisationCard
            }
        });
    }
}
