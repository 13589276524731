import {Options, Vue} from 'vue-class-component';
import OrganisationHeaderComponent
    from '@/pages/organisations/components/organisation-header/organisation-header.component.vue';
import OrganisationCardComponent
    from '@/pages/organisations/components/organisation-card/organisation-card.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import {container} from 'tsyringe';
import {OrganisationsSandbox} from '@/pages/organisations/sandbox/organisations.sandbox';
import {IPinnedService} from '@/pages/home/types/pinned-service';
import {store} from '@/store';
import {IOrganisation} from '@/pages/organisations/types/organisation';

@Options({
    name: 'organisations-component',
    components: {
        OrganisationHeaderComponent,
        OrganisationCardComponent,
    }
})
export default class OrganisationsComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    private sandbox: OrganisationsSandbox = container.resolve(OrganisationsSandbox);

    get organisations(): IOrganisation[] {
        return store.getters._getOrganisations;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.organisations) {
                this.locale = res.organisations;
            }
        });
        this.sandbox.getOrganisations();
    }

}
