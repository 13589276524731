<style src="./organisation-card.component.scss" lang="scss"></style>

<template>
  <v-card class="mb-32">
    <div class="organisation-card">
      <div class="organisation-card__header d-flex align-center">
        <img :src="organisation.icon" alt="icon">
        <div class="organisation-card__title">
          {{ organisation.title }}
        </div>
      </div>
      <div class="organisation-card__info d-flex align-center justify-between">
        <div class="organisation-card__info-services">
          <strong>{{ organisation.count }}</strong> {{ locale.service }}
        </div>
        <div class="organisation-card__info-details">
          <router-link
              :to="{name:`organisations-inner`, params:{
                id:organisation.id,
                name:organisation.title
              } }"
          > {{ locale.details }}
          </router-link>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script src="./organisation-card.component.ts"></script>
